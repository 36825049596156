import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import styles from './layout.module.scss';
import DocumentHead from '../_system/head';
import Header from '../header/header';
const Footer = dynamic(() => import('../footer/footer'));

import Modal from '../modal/modal';
import useAuth from '../_hooks/useAuth';
import useApp from '../_hooks/useApp';
import useUser from '../_hooks/useUser';

const Layout = ({ variation, children, metaTitle, bodyClass, header, footer, data }) => {
	const { appData: { overlayPanel, headerNotitication } } = useApp();
	const { userStoreData: { paidAccessCallout } } = useUser();

	const { authData: { quickViewOpen } } = useAuth();
	const bClass = [styles.body, variation === 'hero' ? styles.hero : styles.inside];
	if (bClass) bClass.push(bodyClass);
	if (overlayPanel) bClass.push(`bodyScrollOff`);
	if (headerNotitication) bClass.push(styles.incPaddingTop); // desktop - add some extra paddinf top (heaer is heigher becouse of notification)

	return (
		<>
			{quickViewOpen && <Modal component="auth/quickView" visible zIndex={1001} />}
			{paidAccessCallout !== null && <Modal component="user/paid-access" zIndex={1001} visible />}
			<DocumentHead data={data}	/>
			<div className={bClass.join(' ')}>
				{header && <Header variation={variation} />}
				<main className={(overlayPanel && overlayPanel.name) ? `main-${overlayPanel.name}` : ''}>{children}</main>
				{footer && <Footer />}
			</div>
		</>
	);
};

Layout.defaultProps = {
	metaTitle: null,
	bodyClass: null,
	header: true,
	footer: true,
	data: {},
	variation: 'inside'
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	metaTitle: PropTypes.string,
	bodyClass: PropTypes.string,
	header: PropTypes.bool,
	footer: PropTypes.bool,
	data: PropTypes.shape({}),
	variation: PropTypes.string
};

export default Layout;
